<template>
    <div class="newTK">
        <div class="redirect" :class="{fast: fastRedirect}">
            <div class="load" v-if="load"></div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                load: true,
                
                newtk: "",
                destiny: "",

                fastRedirect: false
            }
        },

        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            this.newtk = urlParams.get('tk')
            this.destiny = urlParams.get('destiny');

            setTimeout(() => {
                if ( this.newtk == localStorage.getItem("accessToken") ){
                    
                    this.fastRedirect = true
                    window.location.href = `../${this.destiny}`

                } else {

                    localStorage.removeItem('accessToken');
                    localStorage.setItem('accessToken',this.newtk);

                    setTimeout(() => {
                        if ( localStorage.getItem("accessToken") === this.newtk ){
                            window.location.href = `../${this.destiny}`
                        } else {
                            window.location.href = `/`
                        }
                    }, 2000);

                }
            }, 500);
        },

        created () {
            document.title = "DNE Ecosystem";
        }
    }
</script>

<style lang="scss" scoped>
    .newTK{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background: url('~@/assets/images/bgs/bg-15.svg') no-repeat top center #1f3561;
        background-size: cover;

        .redirect{
            background: url(~@/assets/images/icons/dne-symbol.svg) no-repeat center;
            background-size: 100px auto;
            opacity: 0;
            transform: scale(1);

            &:not(&.fast){
                animation: outLoad 2.5s ease-out;
            }

            &.fast{
                animation: outLoadFast .5s ease-out;
            }

            .load{width: 280px; height: 280px;}
        }

        @keyframes outLoad {
            0%{opacity: 1; transform: scale(1);}
            75%{opacity: 1; transform: scale(1);}
            100%{opacity: 0; transform: scale(1.2);}
        }
        
        @keyframes outLoadFast {
            0%{opacity: 1; transform: scale(1);}
            100%{opacity: 0; transform: scale(1.2);}
        }
    }

</style>

<style>
    .newTK + footer{display: none !important;}
</style>